@import "_variables.scss";

@mixin hook-button {
      border-radius: var(--border-radius-small);
}


@mixin hook-card {
      border-radius: var(--border-radius-large);

    &.no-radius {
        border-radius: 0;
    }

    &.uk-card-muted {
        background: var(--global-muted-background);
        color: var(--global-color);
        border: 1px solid var(--global-medium-gray);
       // @if(mixin-exists(hook-card-primary)) {@include hook-card-primary();}
    }

    .uk-card-muted .uk-card-title {
        color: var(--global-color);
       // @if(mixin-exists(hook-card-primary-title)) {@include hook-card-primary-title();}
    }

    .uk-card-muted.uk-card-hover:hover {
       // background-color: $card-primary-hover-background;
       // @if(mixin-exists(hook-card-primary-hover)) {@include hook-card-primary-hover();}
    }
}

@mixin hook-tab-item {

}

@mixin hook-tab-item-hover {

}

@mixin hook-tab-item-active {

}

@mixin hook-tab-item-disabled {

}

@mixin hook-tab {
    li a{
        border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
    }
    .uk-success > a {

    }
}

.uk-tab.uk-subnav-pill > .uk-active > a {
    color: white;
    border-color: $global-color;
}


@mixin hook-subnav {

        &.uk-subnav-pill li a {
            padding-top: calc(var(--global-margin) * 0.4);
            padding-bottom: calc(var(--global-margin) * 0.4);
        }

        li {
            border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
        }

        &.wide {
            li a {
                padding-left: calc(var(--global-margin) * 2 );
                padding-right: calc(var(--global-margin) * 2);
            }
        }
        &.uk-steps  {

            li, li a {
                border-radius: var(--border-radius-small);
            }

            // states
            // active
            // current + complete
            // hover
            > * a {
                background: var(--global-muted-color);
                color: var(--global-inverse-color);
                transition: background 250ms, box-shadow 250ms;
            }


            // complete
            > *.uk-step-complete a:hover,
            > *.uk-step-complete a:focus {
                background-color: var(--global-success-background);
            }

            // complete + active
            > *.uk-step-complete.uk-active a  {
                background-color: var(--global-secondary-background);
            }

            // complete + active + focus/hover
            > *.uk-step-complete.uk-active a:hover,
            > *.uk-step-complete.uk-active a:focus {
                background-color: var(--global-secondary-background);
            }


            // plain active
            > *.uk-active a {
                background-color: var(--global-secondary-background);
                color: var(--global-inverse-color);
                border-color: transparent;
                box-shadow: 0 0 15px 2px rgba(0,0,0,0.4) inset;

            }

            // plain complete
            > *.uk-step-complete a {
                background-color: var(--global-success-background);
                color: var(--global-inverse-color);
                border-color: transparent;
            }

            // any hover/focus
            > * a:hover,
            > * a:focus {
                box-shadow: 0 0 15px 2px rgba(0,0,0,0.4) inset;
            }


        }

}


@mixin hook-form () {
    border: 2px solid $global-border;
    transition: 0.2s ease-in-out;
    transition-property: color, background-color, border;

}

@mixin hook-form-focus () {
    box-shadow: 0 0 9px 0 rgba(0,0,0,0.3);
    border-color: var(--global-primary-background);
}


.uk-modal-full .uk-modal-dialog {
    height: 100%;
}

