.uk-form-icon:not(.uk-form-icon-flip) ~ .uk-input {
    padding-left: 0 !important;
    padding-inline-start: 40px!important;
}

.uk-form-icon {
    left: auto;
    right: auto;
    inset-inline-start: 0;
}

.uk-form-icon-flip {
    left: auto;
    right: auto;
    inset-inline-start: auto;
    inset-inline-end: 0;
}
